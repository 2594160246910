.font-muli {
    font-family: 'Mulish', sans-serif;
}

.font-abel {
    font-family: 'Abel', sans-serif;
}

.sub-menu-header{
    @apply p-2 bg-black/20 border border-black text-white;
}

.menu a {
    @apply btn btn-ghost justify-start;
}

.scrolled {
  @apply text-black bg-white;
}

.scrolled .sub-menu-header{
    @apply bg-white/90 text-black border-white;
}

.scrolled svg {
  stroke: black;
}

.video-docker video {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .video-docker::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  .collapse-title {
    padding-left: 0 !important;
  }

  .text-container p {
    @apply mt-6 text-xl;
  }
  
  .text-container p {
    @apply mt-6;
  }
  
  .text-container ul {
    @apply ml-6;
  }
  
  .text-container h3 {
    @apply mt-8;
  }